<template>
  <div class="continuous">
    <div class="container">
      <Practice v-if="isPractice && showStatus == 2"></Practice>
      <isRight
        :answer="correct_type"
        v-if="isPractice && showStatus == 4"
      ></isRight>
      <div class="main" v-if="showStatus == 2">
        <h1 ref="name">{{ questionList[current].name }}</h1>
        <!-- <img
          :src="questionList[current].src"
          v-show="showStatus"
          :style="{
            width: questionList[current].w + 'px',
            height: questionList[current].h + 'px',
          }"
          alt=""
        /> -->
      </div>
    </div>
    <BlackScreen v-if="showStatus == 3"></BlackScreen>
  </div>
</template>

<script>
import Practice from '@/components/practice.vue';
import BlackScreen from '@/components/blackScreen.vue';
import isRight from '@/components/isRight.vue';
import { saveSubjectiveAns } from '@/api/index';
//测试数据
let practiceData = [
  {
    name: 'E',
  },
  {
    name: 'H',
  },
  {
    name: 'X',
  },
  {
    name: 'P',
  },
  {
    name: 'X',
  },
  {
    name: 'A',
  },
];
let practiceInterval = [1, 1, 2, 2, 4, 4];
export default {
  data() {
    return {
      formalInterval: [
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2,
        2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 4, 4, 4, 4, 4, 4, 4, 4,
        4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4,
      ],
      formalTimeRan: [],
      isPractice: true, //是否练习题
      showStatus: 2, //1、光标+集中 2、答题 3、黑屏 4、结果
      letterList: [
        {
          name: 'A',
        },
        {
          name: 'B',
        },
        {
          name: 'C',
        },
        {
          name: 'D',
        },
        {
          name: 'E',
        },
        {
          name: 'F',
        },
        {
          name: 'G',
        },
        {
          name: 'H',
        },
        {
          name: 'I',
        },
        {
          name: 'J',
        },
        {
          name: 'K',
        },
        {
          name: 'L',
        },
        {
          name: 'M',
        },
        {
          name: 'N',
        },
        {
          name: 'O',
        },
        {
          name: 'P',
        },
        {
          name: 'Q',
        },
        {
          name: 'R',
        },
        {
          name: 'S',
        },
        {
          name: 'T',
        },
        {
          name: 'U',
        },
        {
          name: 'V',
        },
        {
          name: 'W',
        },
        {
          name: 'Y',
        },
        {
          name: 'Z',
        },
      ],
      xList: [
        {
          name: 'X',
        },
        {
          name: 'X',
        },
        {
          name: 'X',
        },
        {
          name: 'X',
        },
      ],
      questionList: [], //题目数据
      current: 0, //当前题数下标
      currentTime: 0, //当前黑屏时间
      original: [], //用户原始操作数据
      startTime: 0, //起始时间
      endTime: 0, //结束时间
      startTimeTotal: 0, //起始总时间
      endTimeTotal: 0, //结束总时间
      totalTime: 0, //用时
      timer1: null,
      timer2: null,
      correct_type: '',
    };
  },
  components: {
    Practice,
    BlackScreen,
    isRight,
  },
  watch: {
    current() {
      this.progress();
    },
  },
  methods: {
    //随机抽取16个
    getRandomArr(arr, count) {
      let randomData = [];
      for (let index = 0; index < count; index++) {
        randomData.push(arr[Math.floor(Math.random() * arr.length)]);
      }
      let newData = randomData.concat(this.xList);
      return this.$util.shuffle(newData);
    },
    //重组所有原始题目
    getLetter() {
      if (this.isPractice) {
        let practiceTimeRan = this.$util.shuffle(practiceInterval);
        practiceData.forEach((item, index) => {
          item.inr = practiceTimeRan[index];
        });
        this.questionList = practiceData;
        this.original = JSON.parse(JSON.stringify(this.questionList));
        this.progress();
      } else {
        this.questionList = [];
        this.formalTimeRan = [];
        for (let i = 0; i < 9; i++) {
          this.questionList = this.questionList.concat(
            this.getRandomArr(this.letterList, 16)
          );
        }
        let questionLists = JSON.parse(JSON.stringify(this.questionList));
        this.formalTimeRan = this.$util
          .shuffle(this.formalInterval)
          .concat(this.$util.shuffle(this.formalInterval))
          .concat(this.$util.shuffle(this.formalInterval));
        questionLists.forEach((item, index) => {
          item.inr = this.formalTimeRan[index];
        });
        this.original = JSON.parse(JSON.stringify(questionLists));
        this.progress();
      }
    },
    //进行中
    progress() {
      //黑屏时间
      this.currentTime = this.original[this.current].inr * 1000;
      this.startTime = this.$util.getTimestamp();
      this.timer1 = setTimeout(() => {
        this.showStatus = 3;
      }, 250);
      this.timer2 = setTimeout(() => {
        if (!this.original[this.current].click) {
          //超时未点击
          this.original[this.current].click = 0;
          this.original[this.current].time = null;
          if (this.original[this.current].name == 'X') {
            //是否正确
            this.original[this.current].correct_type = 1;
          } else {
            this.original[this.current].correct_type = 0;
          }
        }
        if (this.isPractice) {
          //练习反馈结果
          this.correct_type = this.original[this.current].correct_type;
          this.showStatus = 4;
          //结束
          setTimeout(() => {
            if (this.current >= this.questionList.length - 1) {
              this.finish();
              return false;
            } else {
              this.showStatus = 2;
              this.current++;
            }
          }, 2000);
        } else {
          //结束
          if (this.current >= this.questionList.length - 1) {
            this.finish();
            return false;
          }
          this.showStatus = 2;
          this.current++;
        }
      }, this.currentTime);
    },
    //键盘空格事件
    spaceEnter() {
      document.onkeyup = (e) => {
        if (e.keyCode == 32) {
          if (
            //已点击，拦截
            this.original[this.current].click
          ) {
            return false;
          } else {
            this.endTime = this.$util.getTimestamp();
            this.original[this.current].click = 1; //点击了
            this.original[this.current].time = this.endTime - this.startTime; //用时
            if (this.original[this.current].name == 'X') {
              //是否正确
              this.original[this.current].correct_type = 0;
            } else {
              this.original[this.current].correct_type = 1;
            }
          }
        }
      };
    },
    //结束
    finish() {
      this.showStatus = 3;
      this.endTimeTotal = this.$util.getTimestamp();
      this.totalTime = this.endTimeTotal - this.startTimeTotal;
      if (this.isPractice) {
        //练习题
        this.$router.replace({
          path: '/practiceEnd',
          query: {
            source: 'continuous',
          },
        });
      } else {
        let query = {
          fmUserId: this.$util.getStorage('fmUserId'),
          fmProductQuestionId: '3',
          quesNum: '3-1',
          totalTime: this.totalTime,
          dataJason: JSON.stringify(this.original),
          remark:
            'name:名称；inr:间隔时间 单位秒；click：是否点击，1点击 0未点击；time：用时；correct_type：是否正确，1正确 0错误；',
        };
        saveSubjectiveAns(query)
          .then((res) => {
            if (res.code ===0) {
              //大题编号
              sessionStorage.setItem(
                'fmProductQuestionId',
                res.data.fmProductQuestionId
              );
              //小题编号
              sessionStorage.setItem('quesNum', res.data.quesNum);
              //是否完成
              sessionStorage.setItem('isFinished', res.data.isFinished);
              this.$router.replace({
                path: '/getCard',
                query: {
                  id: 3,
                  current: '3-1',
                },
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  beforeDestroy() {
    document.onkeyup = '';
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
  },
  created() {
    if (this.$route.query.practice) {
      this.isPractice = false;
    }
    this.getLetter();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.showStatus == 2) {
        this.$refs.name.style.fontSize =
          document.documentElement.clientWidth * 0.25 + 'px';
      }
      window.addEventListener('resize', () => {
        if (this.showStatus == 2) {
          this.$refs.name.style.fontSize =
            document.documentElement.clientWidth * 0.25 + 'px';
        }
      });
    });
    this.startTimeTotal = this.$util.getTimestamp();
    this.spaceEnter();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .continuous {
    display: table-cell;
    vertical-align: middle;
    .main {
      height: 590px;
      line-height: 590px;
      margin: 0 auto;
      text-align: center;
      h1 {
        color: #333333;
        font-size: 360px;
        font-family: Arial;
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .continuous {
    display: table-cell;
    vertical-align: middle;
    .main {
      height: 767px;
      line-height: 767px;
      margin: 0 auto;
      text-align: center;
      h1 {
        color: #333333;
        font-size: 468px;
        font-family: Arial;
      }
    }
  }
}
</style>
